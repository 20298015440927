<template>
  <div class="list_box">
    <li
      v-for="(item, index) in dataList"
      :key="index"
      @click="toDetails(item.id)"
    >
      <div class="pro_pic">
        <img :src="item.picHead" alt="图片" />
      </div>
      <h2>{{ item.title }}</h2>
      <p>&yen;{{ item.price }}</p>
      <span>月销{{ item.salesVolume }}</span>
    </li>
  </div>
</template>
<script>
export default {
  props: {
    dataList: {
      type: Array,
    },
    type: {
      type: Number,
    },
  },
  mounted() {},
  methods: {
    toDetails(id) {
      this.$router.push({
        path: "productionDetails",
        query: {
          id: id,
          type: this.type,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.list_box {
  width: 95%;
  margin: 0 auto 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  li {
    margin-top: 20px;
    width: 350px;
    background-color: #232a44;
    padding-bottom: 20px;
    border-radius: 10px;
    .pro_pic {
      width: 350px;
      height: 350px;
      background-color: #374372;
      border-radius: 10px;
      img {
        border-radius: 10px;
      }
    }
    h2 {
      padding: 0 20px;
      color: #8cbcd6;
      font-size: 26px;
      margin-top: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    p {
      padding: 0 20px;
      color: #00ccff;
      font-size: 30px;
      margin: 20px 0;
    }
    span {
      padding: 0 20px;
      color: #7f89b3;
      font-size: 22px;
    }
  }
}
</style>
